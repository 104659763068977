<template>
  <div class="search-bar" :class="{ show, inline, resultsShowed: results.length }">
    <form @submit.prevent>
      <SearchIcon class="search-icon" />
      <input
        ref="searchInput"
        v-model="keyword"
        type="text"
        placeholder="Search..."
        autocomplete="off"
        tabindex="0"
        @keyup.enter.exact.prevent="redirectToSearchPage"
        @keyup.arrow-down.exact.prevent="arrowsPress"
        @keyup.arrow-up.exact.prevent="arrowsPress"
        @keyup="search"
        @focus="search"
      >

      <ul class="search-results">
        <template v-if="results.length">
          <li v-for="(result, idx) in results" :key="idx">
            <a :title="result.title" :class="{ focused: idx === focus }" @click.prevent="redirectToSearchPage(idx)">
              {{ result.title }}
            </a>
          </li>
          <li class="see-all">
            <a :class="{ focused: -1 === focus }" @click.prevent="redirectToSearchPage(-1)">
              Show all results
            </a>
          </li>
        </template>
        <li v-else-if="!results.length && keyword">
          <span>Sorry, but nothing matched your filter.</span>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import global from '@itbpbg/lms-components/src/mixins/global';
import SearchIcon from '../../../assets/svg/icon-search.svg?vue-component';

export default {
  components: { SearchIcon },
  mixins: [global],
  props: {
    show: Boolean,
    inline: Boolean,
  },
  data() {
    return {
      keyword: '',
      results: [],
      focus: -1,
    };
  },
  watch: {
    show() {
      if (this.show) {
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 300);
      }
    },
  },
  methods: {
    redirectToSearchPage(idx) {
      const { keyword } = this;
      if (!keyword || keyword.length < 3) {
        return false;
      }
      if (typeof idx === 'number') {
        this.focus = idx;
      }
      if (this.focus >= 0) { // when click enter and item of dropdown is focused
        this.clearSearch();
        document.querySelector('.search-icon-wrapper a').click();
        return this.$router.push(this.blogPostURL(this.results[this.focus]));
      }
      if (this.focus === -1) { // when clicked on `see all`
        this.clearSearch();
        document.querySelector('.search-icon-wrapper a').click();
      }
      return this.$router.push({ path: '/blog/', query: { search: encodeURIComponent(keyword) } });
    },
    search: debounce(async function delay(e) {
      if (!e) {
        return;
      }

      if (e.code === 'Enter' || e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        return;
      }

      if (this.keyword.length < 3) {
        this.results = [];
        return;
      }

      this.focus = -1;
      const { posts } = await this.$axios.$get(`/blog/posts?page=1${this.keyword ? `&filter[q]=${this.keyword}` : ''}&limit=5`).catch(() => {}) || {};
      this.results = posts;
    }, 500),
    clearSearch() {
      setTimeout(() => {
        this.keyword = '';
        this.focus = -1;
        this.results = [];
      }, 200);
    },
    arrowsPress(e) {
      if (e.code === 'ArrowDown') {
        if (this.focus >= this.results.length - 1) {
          this.focus = -1;
        } else {
          this.focus += 1;
        }
        return;
      }

      if (this.focus === -1) {
        this.focus = this.results.length - 1;
      } else {
        this.focus -= 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  position: absolute;
  right: 0;
  width: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all var(--duration), width 600ms ease;
  height: 52px;

  &.show {
    width: 374px;
    visibility: visible;
    opacity: 1;
    z-index: 0;

    form {
      background: var(--main-dark-shade-2);
      border: 1px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
      border-radius: 5px;
    }
  }

  &.resultsShowed {
    form {
      border-radius: 5px 5px 0 0;
    }

    .search-results {
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-top: 0;
    }
  }

  form {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-dark-shade-2);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-16);

    input {
      display: block;
      border: 0;
      width: 100%;
      height: 100%;
      padding: 5px 0 2px;
      background-color: transparent;
      text-indent: 18px;
      color: var(--main-light-shade-3);

      &::placeholder {
        color: rgba(255, 255, 255, 0.36);
        font-size: var(--fs--base);
      }

      &:focus {
        outline: none;
      }
    }

    svg path[stroke] {
      stroke: rgba(255, 255, 255, 0.36);
    }
  }

  .search-results {
    position: absolute;
    left: -1px;
    right: 0;
    top: 101%;
    width: 374px;
    text-align: left;
    padding: 0;
    z-index: 1;
    overflow: hidden;
    margin: 0;
    list-style: none;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    background: var(--main-dark-shade-2);

    li {
      display: flex;

      a {
        font-weight: normal;
        font-size: 18px;
        line-height: 120%;
        color: var(--main-light-shade-4);
        padding: var(--spacing-8);
        padding-left: var(--spacing-56);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        &:hover,
        &:focus,
        &.focused {
          background-color: var(--main-dark-light-3);
          text-decoration: none;
          color: var(--main-light);
        }

        &:active {
          background-color: var(--main-dark-shade-2);
          color: var(--main-light);
        }
      }

      span {
        padding: var(--spacing-16);
      }

      &.see-all {
        a {
          font-weight: 600;
          letter-spacing: 0.02em;
          color: var(--accent-shade-1);
          padding-bottom: var(--spacing-14);
        }
      }
    }
  }

  &.inline {
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: var(--spacing-80) auto 0;

    &.show {
      form {
        background-color: var(--main-light-shade-2);
      }
    }

    form {
      input {
        color: var(--main-dark-light-4);

        &::placeholder {
          color: var(--main-dark-light-4);
        }
      }

      svg {
        path[stroke] {
          stroke: var(--main-dark-light-4);
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .search-bar {
    .search-results {
      width: 100%;
      margin: 0 auto;
      color: var(--true-white);
    }

    &.show {
      z-index: 1;
    }
  }
}

@include bp($bp-mobile) {
  .search-bar {
    &.inline {
      max-width: unset;
      z-index: 1;

      .search-results {
        width: 100%;
      }
    }
  }
}
</style>
