<template>
  <div v-if="!cookiesAccepted && showPopupModal && !$route.path.startsWith('/f/') && !$route.query.screenshot" id="cookie-law-info-bar">
    <div class="container">
      <span>{{ companyName }} uses cookies to personalize content, ads and to help us improve performance.
        We also share information about your use of our website with our advertising and analytics partner who may
        combine it with other information you’ve provided to them or that they’ve collected from your use of their
        services. To find out more,
        read our
        <nuxt-link to="/privacy-policy/" target="_blank" class="more">Privacy Policy</nuxt-link>
        and
        <nuxt-link to="/cookie-policy/" target="_blank" class="more">Cookie Policy</nuxt-link>.
      </span>
      <span class="ok btn-primary" @click.prevent="acceptCookies"> Accept </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookiesAccepted: true,
    };
  },
  computed: {
    companyName() {
      if (this.$config.platform === 'ds') {
        return this.$config.platformName;
      }

      return '365 Company Ltd';
    },
    showPopupModal() {
      return !this.$store.state.modals.opened.some((modal) => modal.name === 'gallery');
    },
  },
  mounted() {
    this.cookiesAccepted = localStorage.getItem('cookiesAccepted');
  },
  methods: {
    acceptCookies() {
      this.cookiesAccepted = true;
      localStorage.setItem('cookiesAccepted', this.cookiesAccepted);
    },
  },
};
</script>

<style lang="scss" scoped>
#cookie-law-info-bar {
  font-size: 14px;
  margin: 0 auto;
  padding: 12px 10px;
  position: fixed;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  z-index: 9999;
  display: block;
  left: 0;
  font-weight: 400;
  box-shadow: 0 3px 8px rgba(0, 12, 31, 0.05);
  opacity: 0.95;
  background-color: var(--main-dark-light-2);
  color: var(--main-light);
  font-family: inherit;
  bottom: 0;
  transition: all 300ms ease;
  line-height: 18px;

  .container {
    display: flex;
    align-items: center;

    .ok {
      display: inline-block;
      padding: 0.9em 1.7em;
      text-decoration: none;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-left: 10px;
    }

    a.more {
      display: inline-block;
      color: #3D989E;
      text-decoration: underline;
    }
  }
}

@include bp($bp-laptop) {
  #cookie-law-info-bar .container .ok {
    width: 100px;
    height: 44px;
    margin-left: 8px;
  }
}

@include bp($bp-mobile) {
  #cookie-law-info-bar {
    padding: 24px 0;

    .container {
      display: block;

      >span {
        display: block;
      }

      .ok {
        margin-top: 12px;
        margin-left: 0;
        padding: 12px 25px;
      }
    }
  }
}

@include bp($bp-phone) {
  #cookie-law-info-bar .container a.ok {
    width: 100%;
  }
}

.fa {
  #cookie-law-info-bar {
    background: rgba(40, 52, 84, 0.95);
    backdrop-filter: blur(10px);

    .container {
      a.more {
        color: var(--accent-light-3);
      }
    }
  }
}
</style>
