<template>
  <li class="search">
    <div class="search-icon-wrapper">
      <a href="" :class="{ active: show }" @click.prevent="searchBarExpand">
        <SearchIcon class="search-icon" />
      </a>
    </div>
    <SearchBar :show="show" />
  </li>
</template>

<script>
import SearchIcon from '../../../assets/svg/icon-search.svg?vue-component';
import SearchBar from './SearchBar';

export default {
  components: { SearchIcon, SearchBar },
  data: () => ({ show: false }),
  created() {
    if (process.client) {
      window.addEventListener('click', (e) => {
        if (!this.$el.contains(e.target) && this.show) {
          this.show = !this.show;
        }
      });
    }
  },
  methods: {
    searchBarExpand() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.search-icon path[stroke] {
  stroke: var(--main-light);
}

.search-icon:hover path {
  stroke: var(--main-light-shade-4);
}
</style>
