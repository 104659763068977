<template>
  <div class="icons-social">
    <a
      v-for="(link, i) in currentPlatformLinks"
      :key="i"
      :href="link" :title="`${$config.platformName} on ${socialNames[i]}`"
      target="_blank" rel="noopener"
      :data-event="`footer_${faLinks[i].split('.')[1] === undefined ? 'twitter' : faLinks[i].split('.')[1]}.click`"
      data-direct-send
    >
      <component :is="`Icon${socialNames[i]}`" />
    </a>
  </div>
</template>

<script>
import IconFacebook from '../assets/svg/icon-facebook.svg?vue-component';
import IconTwitter from '../assets/svg/icon-social-twitter.svg?vue-component';
import IconYoutube from '../assets/svg/icon-youtube.svg?vue-component';
import IconLinkedin from '../assets/svg/icon-linkedin.svg?vue-component';
import IconInstagram from '../assets/svg/icon-instagram.svg?vue-component';

export default {
  components: {
    IconFacebook,
    IconTwitter,
    IconYoutube,
    IconLinkedin,
    IconInstagram,
  },
  data() {
    return {
      dsLinks: [
        'https://www.facebook.com/365DataScience',
        'https://twitter.com/365datascience',
        'https://www.youtube.com/365datascience',
        'https://www.linkedin.com/company/365datascience/',
        'https://www.instagram.com/365datascience/',
      ],
      faLinks: [
        'https://www.facebook.com/365financialanalyst',
        'https://twitter.com/365finanalyst',
        'https://www.youtube.com/@365FinancialAnalyst',
        'https://www.linkedin.com/company/365financialanalyst/',
        'https://www.instagram.com/365financialanalyst/',
      ],
    };
  },
  computed: {
    currentPlatformLinks() {
      return this.$config.platform === 'fa' ? this.faLinks : this.dsLinks;
    },
    socialNames() {
      return [
        'Facebook',
        'Twitter',
        'Youtube',
        'Linkedin',
        'Instagram',
      ];
    },
  },
};
</script>

<style lang="scss">
.icons-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  margin-top: var(--spacing-48);

  a {
    display: block;
    margin-right: var(--spacing-24);

    svg path {
      transition: all var(--duration) ease;
    }

    &:hover {
      svg path[fill] {
        fill: var(--main-light-shade-3);
      }

      svg path[stroke] {
        stroke: var(--main-light-shade-3);
      }
    }

    &:active {
      svg path[fill] {
        fill: var(--main-light-shade-4);
      }

      svg path[stroke] {
        stroke: var(--main-light-shade-4);
      }
    }
  }
}

@include bp($bp-mobile) {
  .icons-social {
    justify-content: flex-start;
  }
}
</style>
